.cropImage-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: rgba(237, 236, 236, 0.1); */
    z-index: 998;
    overflow-y: auto;
    backdrop-filter: blur(1px);
    /* Adjust the blur intensity as needed */
    /* background-color: rgba(237, 236, 236, 0.1); */
    /* Background color with opacity */
}

.cropImage-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #1f1c2b; */
    /* padding: 1rem; */
    /* border-radius: 90px; */
    max-height: 80vh;
    z-index: 1000;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    display: flex;
    flex-direction: column;
}

.ReactCrop__crop-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
}

.ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);
    cursor: move;
}

.ReactCrop__crop-selection:not(.ReactCrop--no-animate .ReactCrop__crop-selection) {
    animation: marching-ants 1s;
    background-image: linear-gradient(to right, #fff 50%, #444 50%), linear-gradient(to right, #fff 50%, #444 50%), linear-gradient(to bottom, #fff 50%, #444 50%), linear-gradient(to bottom, #fff 50%, #444 50%);
    background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    color: #fff;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.ReactCrop *,
.ReactCrop *:before,
.ReactCrop *:after {
    box-sizing: border-box;
}
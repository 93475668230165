.review-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(237, 236, 236, 0.1) ; */
  z-index: 1001;
  overflow-y: auto;
  backdrop-filter: blur(2px);
}

.review-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: #1f1c2b; */
  padding: 1rem;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1000;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  display: flex;
  flex-direction: column;
}

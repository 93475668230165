.side-menu {
  width: 250px;
  height: 100vh;
  position: fixed;
  direction: "ltr";
  top: 0;
  left: -250px;
  transition: left 0.3s ease;
  z-index: 100;
}
.side-menu.ar {
  left: auto;
  right: -250px;
  transition: right 0.3s ease;
}
.side-menu.open {
  left: 0;
}
.side-menu.open.ar {
  left: auto;
  right: 0;
}
.icons {
  margin-right: 0.5rem;
  color: #f07eaa;
  width: 24px;
  height: 24px;
}

.icons.ar {
  margin-right: 0;
  margin-left: 0.5rem;
}
.close-btn {
  margin-bottom: 10px;
}

.dark-mode {
  background-color: #1f1c2b;
  color: white;
}

.light-mode {
  background-color: #f0f0f0;
  color: black;
}
